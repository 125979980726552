<template>
  <el-row type="flex" class="row-bg" justify="center">
    <!-- <el-col :xl="6" :lg="7">
      <h2>欢迎来到签到管理系统</h2>
      <el-image
        :src="require('@/assets/MarkerHub.jpg')"
        style="height: 180px; width: 180px;"
      ></el-image>
    </el-col> -->

    <!-- <el-col :span="1">
      <el-divider direction="vertical"></el-divider>
    </el-col> -->
    <el-col :xl="5" :lg="6" class="loginBox">
      <h2>欢迎登录签到管理系统</h2>
      <el-form
        :model="loginForm"
        :rules="rules"
        ref="loginForm"
        label-width="80px"
      >
        <el-form-item label-width="0" prop="username" style="width: 100%;">
          <el-input v-model="loginForm.username" placeholder="请输入用户名">
            <template slot="prepend"
              ><i class="el-icon-user-solid"></i
            ></template>
          </el-input>
        </el-form-item>
        <el-form-item label-width="0" prop="password" style="width: 100%;">
          <el-input
            v-model="loginForm.password"
            placeholder="请输入密码"
            type="password"
          >
            <template slot="prepend">
              <img class="passwordBox" src="../assets/password.png" alt="" />
            </template>
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="验证码" prop="code" style="width: 380px;">
          <el-input
            v-model="loginForm.code"
            style="width: 172px; float: left"
            maxlength="5"
          ></el-input>
          <el-image
            :src="captchaImg"
            class="captchaImg"
            @click="getCaptcha"
          ></el-image>
        </el-form-item> -->

        <el-form-item label-width="0" class="loginbtn">
          <el-button type="primary" @click="submitForm('loginForm')"
            >立即登录</el-button
          >
          <!-- <el-button @click="resetForm('loginForm')">重置</el-button> -->
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
import qs from "qs";

export default {
  name: "Login",
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
        // code: "11111",
        // token: "",
        types: "6",
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 5, max: 5, message: "长度为 5 个字符", trigger: "blur" },
        ],
      },
      captchaImg: null,
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.post("/login", this.loginForm).then((res) => {
            // const jwt = res.headers['authorization']
            const jwt = res.data.data.Authorization;
            this.$store.commit("SET_TOKEN", jwt);
            this.$router.push("/");
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // getCaptcha() {
    //   this.$axios.get("/captcha").then((res) => {
    //     this.loginForm.token = res.data.data.token;
    //     this.captchaImg = res.data.data.captchaImg;
    //     this.loginForm.code = "";
    //   });
    // },
  },
  created() {
    // this.getCaptcha();
  },
};
</script>

<style scoped>
.el-row {
  background-color: #fafafa;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.el-row.row-bg {
  background-image: url("../assets/Frame1.png");
  background-size: 100% 100%;
}
.el-divider {
  height: 200px;
}
.loginBox {
  background: #fff;
  border-radius: 5px;
  padding: 0 15px;
}
.loginBox h2 {
  padding: 20px 0;
}

.captchaImg {
  float: left;
  margin-left: 8px;
  border-radius: 4px;
}
/* .loginbtn {
  padding: 0 20px;
} */
.el-button {
  width: 100%;
}
.passwordBox {
  width: 14px;
}
</style>
